/**
 * Website header
 */

import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { rhythm } from "../utils/typography"
import Navbar from "./navbar"

class Header extends React.Component {
  render() {
    return (
      <StaticQuery
        query={HeaderQuery}
        render={data => {
          const { author } = data.site.siteMetadata
          return (
            <header
              style={{
                position: `relative`,
                marginBottom: 32,
              }}
            >
              <Container>
                <StyledLink to="/">
                  <StyledImage
                    fixed={data.avatar.childImageSharp.fixed}
                    alt={author}
                    style={
                      {
                        width: `75px`,
                        height: `75px`
                      }
                    }
                    imgStyle={{
                      borderRadius: `50%`,
                      width: `75px`,
                      height: `75px`
                    }}
                  />
                </StyledLink>
                <Link to="/" style={{ color: `inherit`, boxShadow: `none` }}>
                  <p
                    style={{
                      marginBottom: 0,
                      fontFamily: `Raleway, sans-serif`,
                      lineHeight: 1.5,
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    Богдан Звягинцев
                  </p>
                </Link>
                <NavbarWrapper>
                  <Navbar />
                </NavbarWrapper>
              </Container>
            </header>
          )
        }}
      />
    )
  }
}

const HeaderQuery = graphql`
  query HeaderQuery {
    avatar: file(absolutePath: { regex: "/photo.jpg/" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Header

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 16px;
`

const StyledLink = styled(Link)`
  position: absolute;
  left: calc(-75px - ${rhythm(1)});
  color: inherit;
  box-shadow: none;

  @media all and (max-width: 1024px) {
    position: relative;
    left: 0;
    margin-right: ${rhythm(1 / 2)};
  }
`

const StyledImage = styled(Image)`
  // @media all and (max-width: 1024px) {
    width: 65px;
    height: 65px;
  // }
`

const NavbarWrapper = styled.div`
  @media all and (max-width: 1024px) {
    margin-top: ${rhythm(1 / 2)};
  }
`
