import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "body": {
      // fontSize: `1.125rem`,
      // fontSize: `1.0625rem`,
      fontSize: `1rem`,
      fontFamily: `Open Sans`
    },
    // Стиль шрифта для текста статей
    ".content": {
      fontFamily: `Merriweather`,
      fontSize: `1.0625rem`,
    },
    "blockquote": {
      fontSize: `1.0625rem`,
    },
    "h1": {
      fontFamily: `Raleway`,
      fontWeight: 800
    },
    "h2, h3": {
      fontFamily: `Raleway`,
      fontWeight: 700
    },
    // Размер шрифта для кода
    "code": {
      fontSize: `0.9375rem`,
      // fontSize: `1rem`,
    },
    // Стиль для inline-кода
    "code:not(.vscode-highlight-code)": {
      background: `#f1f1f1`,
      padding: `.2em .4em;`,
      borderRadius: `3px`,
    },
    // Отступ для списков и цитат
    "ul, ol, blockquote": {
      marginLeft: `1.75rem`
    },
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    "a": {
      color: `#1A60F5`,
      boxShadow: `0 1px 0 0 #BACFFC`,
      transition: `all 0.2s`
    },
    "p": {
      marginBottom: `1rem`
    }
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
