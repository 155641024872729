/**
 * Component contains navigation menu for header
 */

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

class Navbar extends React.Component {
  render() {
    return (
      <nav>
        <Menu>
          <MenuItem><Link to="/blog">Блог</Link></MenuItem>
          <MenuItem><a href="tg://resolve?domain=bzvyagintsev" target="_blank" rel="noreferrer">Телеграм</a></MenuItem>
          <MenuItem><a href="https://instagram.com/bzvyagintsev" target="_blank" rel="noreferrer">Инстаграм</a></MenuItem>
          {/* <MenuItem><a href="https://instagram.com/bzvyagintsev" target="_blank" rel="noreferrer">Твиттер</a></MenuItem> */}
          <MenuItem><a href="https://www.facebook.com/bogdan.zvyagintsev.37" target="_blank" rel="noreferrer">Facebook</a></MenuItem>
          <MenuItem><a href="mailto:b.zvyagintsev@gmail.com" target="_blank" rel="noreferrer">b.zvyagintsev@gmail.com</a></MenuItem>
        </Menu>
      </nav>
    )
  }
}

export default Navbar

const Menu = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  padding: 0;
  margin: 0;
  // font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;

  @media all and (max-width: 1024px) {
    flex-flow: row wrap;
  }
`
const MenuItem = styled.li`
  padding: 0;
  margin: 0 12px 0 0;
`
