import React from "react"
import styled from "styled-components"
import { rhythm } from "../utils/typography"

class Footer extends React.Component {
  render() {
    return (
        <FooterWrapper>
          <div
            style={{
              marginLeft: `auto`,
              marginRight: `auto`,
              maxWidth: rhythm(26),
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}
          >
            © Богдан Звягинцев, {new Date().getFullYear()}
          </div>
        </FooterWrapper>
    )
  }
}

export default Footer

const FooterWrapper = styled.footer`
  margin: 24px 0;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: hsla(0, 0%, 0%, 0.7);
`
